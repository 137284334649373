<template>
  <div>
    <b-row>
      <b-col cols="12">
        <b-card no-body>
          <b-card-body>
            <b-row>
              <b-col cols="12" md="6" v-if="active_role == 'superadmin'">
                <b-form-group label="Corporate" label-for="title">
                  <b-form-select 
                    id="country"
                    v-model="data.corporate"
                    :options="corporate"
                    value-field="id"
                    text-field="name"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12" md="6">
                <b-form-group label="Title" label-for="title">
                  <b-form-input
                    v-model="data.title"
                    id="title"
                    placeholder="Title"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="12" md="6">
                <b-form-group label="Link" label-for="link">
                  <b-form-input
                    v-model="data.link"
                    id="link"
                    placeholder="Link"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group label="Description" label-for="description">
                  <b-form-textarea
                    v-model="data.description"
                    id="description"
                    placeholder="Description"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group label="Upload Logo" label-for="upload_logo">
                  <b-form-file
                    ref="file-input"
                    accept="image/*"
                    placeholder="Choose a file or drop it here..."
                    drop-placeholder="Drop file here..."
                    @change="setFileImage"
                  />
                </b-form-group>
                <div v-if="data.file.url" 
                  style="position: relative; width: 50%">
                  <b-img
                    :src="data.file.url"
                    rounded
                    alt="Rounded image"
                    class="d-inline-block mr-1 mb-1 w-100"
                  />
                  <div style="position: absolute; top: 0.45rem; right: 0.5rem"
                    @click="resetImage">
                    <b-button
                      variant="gradient-danger"
                      class="btn-icon rounded-circle"
                    >
                      <feather-icon icon="XIcon" />
                    </b-button>
                  </div>
                </div>
              </b-col>
              <b-col cols="12">
                <b-button variant="primary" @click="submit">Submit</b-button>
              </b-col>
            </b-row>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
    <b-modal
      static
      v-model="modal_crop_image"
      id="crop-image"
      ref="cropimage"
      title="Crop Gambar"
      @hidden="resetCropImage"
      @ok="handleCropImage"
    >
      <vue-cropper
        ref="cropper"
        :src="data.file_raw"
        :crop-box-resizable="false"
        :toggle-drag-mode-on-dblclick="false"
      />
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="primary"
        class="mt-1"
        @click="setCropArea"
      >
        Set Default Crop
      </b-button>
    </b-modal>
  </div>
</template>

<script>
  import {
    BRow, BCol,
    BCard, BCardBody,
    BFormGroup, BFormInput, BFormTextarea, BFormFile, BFormSelect, BButton,
    BImg
  } from 'bootstrap-vue'
  import Ripple from 'vue-ripple-directive'
  import VueCropper from 'vue-cropperjs';
  import 'cropperjs/dist/cropper.css';

  export default {
    components: {
      BRow, BCol,
      BCard, BCardBody,
      BFormGroup, BFormInput, BFormTextarea, BFormFile, BFormSelect, BButton, 
      BImg,
      VueCropper
    },
    directives: {
      Ripple,
    },
    data() {
      return {
        data: {
          title: null,
          link: null,
          description: null,
          corporate: null,
          file: {
            url: null,
            image: null
          },
          file_raw: null
        },
        modal_crop_image: false,
        active_role: null
      }
    },
		created() {
      this.checkAuth()
      this.getCorporate()
      this.active_role = localStorage.getItem('role')
    },
    computed: {
      corporate() {
        return this.$store.state.corporate.corporate
      }
    },
    methods: {
      getCorporate() {
        this.$store.dispatch('corporate/loadCorporate')
      },
      setFileImage(event) {
        const file = event.target.files[0]

        if (file.type.indexOf('image/') === -1) {
          this.$refs['file-input'].reset()
          this.runToast('error', 'Please select an image file')
          return;
        }

        if (typeof FileReader === 'function') {
          const reader = new FileReader();

          reader.onload = (event) => {
            let size_file = Math.floor(file.size / 1024)
            
            if(size_file <= 1024) {
              this.data.file_raw = event.target.result;
          
              this.showModalCrop()

              // rebuild cropperjs with the updated source
              this.$refs.cropper.replace(event.target.result)
            } else {
              this.$refs['file-input'].reset()
              this.runToast('error', 'Ukuran file lebih dari 1 MB')
            }
          };
          reader.readAsDataURL(file)
        } else {
          this.$refs['file-input'].reset()
          this.runToast('error', 'Sorry, FileReader API not supported')
        }
      },
      submit() {
        let cid = localStorage.getItem('client_id')
        let corporateID = localStorage.getItem('corporate_id')
        let role = localStorage.getItem('role')
        let input = this.data
        const data = new FormData()

        data.append('title', input.title)
        data.append('image', input.file.image)
        data.append('description', input.description)
        data.append('link', input.link)

        if(role == 'corporate') {
          data.append('corporate', corporateID)
        } else {
          data.append('corporate', input.corporate)
        }

        const config = {
          headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('token')
          }
        }

        this.$axios.post(`${cid}/banner`, data, config)
          .then(res => {
            // Success code
            this.$bvToast.toast('Success', {
              title: 'Add Banner',
              variant: 'success',
              solid: true,
              toaster: 'b-toaster-bottom-right',
              autoHideDelay: 3000
            })

            setTimeout(() => {
              this.$router.push({ path: '/banner' })
            }, 3000);
          })
          .catch(error => {
            console.log(error)
          })
      },

      showModalCrop() {
        this.modal_crop_image = true
      },
      resetImage() {
        this.$refs['file-input'].reset()

        this.data.file.image = null
        this.data.file.url = null
        this.data.file_raw = null
      },
      setCropArea() {
        this.$refs.cropper.setCropBoxData({
          width: 512,
          height: 512
        })
      },
      resetCropImage() {
        if(!this.data.file_raw) {
          this.$refs['file-input'].reset()
        }
      },
      handleCropImage() {
        this.data.file.url = this.$refs.cropper.getCroppedCanvas().toDataURL()
        
        let block = this.data.file.url.split(";");
        let content_type = block[0].split(":")[1]
        let real_data = block[1].split(",")[1];
        this.data.file.image = this.b64toBlob(real_data, content_type)
      },
      b64toBlob(b64Data, contentType, sliceSize) {
        contentType = contentType || '';
        sliceSize = sliceSize || 512;

        var byteCharacters = atob(b64Data);
        var byteArrays = [];

        for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            var slice = byteCharacters.slice(offset, offset + sliceSize);

            var byteNumbers = new Array(slice.length);
            for (var i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }

            var byteArray = new Uint8Array(byteNumbers);

            byteArrays.push(byteArray);
        }

        var blob = new Blob(byteArrays, {type: contentType});
        return blob;
      }
    }
  }
</script>
